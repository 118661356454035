

<template>
  <div class="dx-field"></div>
</template>

<script>
// import DxButton from "devextreme-vue/button";
// import DxCheckBox from "devextreme-vue/check-box";
// import DxTextBox from "devextreme-vue/text-box";
// import DxValidationGroup from "devextreme-vue/validation-group";
// import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
// import { mapActions } from "vuex";
// import router from "../router";
// import notify from "devextreme/ui/notify";
// import auth from "../auth";
import axios from "axios";

// import defaultLayout from "../layouts/side-nav-inner-toolbar";
// import managerLayout from "../layouts/side-nav-inner-toolbar-manager";
// import adminLayout from "../layouts/side-nav-inner-toolbar-admin";
// const base_url = process.env.VUE_APP_BASE_URL;
// import axios from "axios";

export default {
  name: "Login",
  components: {
    // DxButton,
    // // DxCheckBox,
    // DxTextBox,
    // DxValidator,
    // DxRequiredRule,
    // DxValidationGroup,
  },
  created() {
    this.logout();
  },
  data() {
    return {};
  },
  methods: {
    // ...mapActions("user", ["setUser", "setUserStatus"]),
    logout() {
      axios.get("logout").then(() => {
        //remove localStorage
        localStorage.removeItem("loggedIn");
        //redirect
        return this.$router.push({ name: "login" });
      });
    },
  },

  computed: {},
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
// .app {
//   background-color: #2f8649;
//   background: url("~@/assets/images/fleet-background.png");
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   // display: flex;
//   // height: 100%;
//   // width: 100%;
// }
.login-header {
  text-align: center;
  margin-bottom: 10px;
  .title1 {
    color: $base-accent;
    font-weight: bold;
    font-size: 30px;
    margin: 0;
  }
  .title2 {
    color: $base-accent;
    font-weight: bold;
    font-size: 20px;
    margin: 0;
  }
}

.version {
  color: gray;
  font-weight: bold;
  font-size: 12px;
  margin: 0;
}
.login-notif {
  text-align: center;
  margin-bottom: 40px;
  color: red;
  font-weight: bold;
  font-size: 14px;
}
</style>
