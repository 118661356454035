

<template>
  <dx-validation-group :ref="groupRefKey">
    <div class="login-header">
      <img :src="logoimage" alt="" />
      <div class="title1">
        <!-- <span style="display: inline-block"><img :src="icontruck" /></span>&nbsp; -->
        {{ title }}
      </div>
      <div class="version">Versi {{ version }}</div>
      <!-- <div>Sign In to your account</div> -->
    </div>
    <div class="login-notif">
      <div v-if="loginstatus === 1">Email atau Password Anda salah.</div>
    </div>
    <form @submit.prevent="login">
      <div class="dx-field">
        <dx-text-box
          placeholder="Username"
          width="100%"
          :value.sync="login"
          @enter-key="onEnterKey"
        >
          <dx-validator>
            <dx-required-rule message="Login is required" />
          </dx-validator>
        </dx-text-box>
      </div>

      <div class="dx-field">
        <dx-text-box
          placeholder="Passwords"
          width="100%"
          mode="password"
          :value.sync="password"
          @enter-key="onEnterKey"
        >
          <dx-validator>
            <dx-required-rule message="Password is required" />
          </dx-validator>
        </dx-text-box>
      </div>
    </form>
    <div class="dx-field">
      <!-- <dx-check-box :value.sync="rememberUser" text="Remember me" /> -->
    </div>

    <div class="dx-field">
      <dx-button
        type="default"
        text="Login"
        width="100%"
        @click="onLoginClick"
      />
    </div>
  </dx-validation-group>
</template>

<script>
import DxButton from "devextreme-vue/button";
// import DxCheckBox from "devextreme-vue/check-box";
import DxTextBox from "devextreme-vue/text-box";
import DxValidationGroup from "devextreme-vue/validation-group";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { mapActions } from "vuex";
import router from "../router";
import notify from "devextreme/ui/notify";
// import auth from "../auth";
import axios from "axios";

// import defaultLayout from "../layouts/side-nav-inner-toolbar";
// import managerLayout from "../layouts/side-nav-inner-toolbar-manager";
// import adminLayout from "../layouts/side-nav-inner-toolbar-admin";
let base_url = process.env.VUE_APP_BASE_URL;

export default {
  name: "Login",
  components: {
    DxButton,
    // DxCheckBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationGroup,
  },
  data() {
    let currentUrl = window.location.origin;

    if (currentUrl.includes("localhost")) {
      base_url = "http://127.0.0.1:84";
      // base_url = "http://bewms.local:10080";
    } else if (currentUrl.includes("10.1.10.21")) {
      base_url = "http://10.1.10.21:84";
    } else {
      base_url = process.env.VUE_APP_BASE_URL;
    }

    return {
      logoimage: require("@/assets/images/ljr-logo-128x96.png"),
      icontruck: require("@/assets/images/clipboard.svg"),
      title: this.$appInfo.title,
      version: this.$appVersion.version,
      login: "",
      password: "",
      rememberUser: false,

      //state loggedIn with localStorage
      loggedIn: localStorage.getItem("loggedIn"),
      //state token
      token: localStorage.getItem("token"),
      //state user
      user: [],
      //state validation
      validation: [],
      //state login failed
      loginFailed: null,
      loginstatus: "",
      groupRefKey: "targetGroup",
    };
  },
  methods: {
    ...mapActions("user", ["setUser", "setUserStatus"]),

    onLoginClick() {
      // this.validationGroup.validate();
      if (!this.validationGroup.validate().isValid) {
        return;
      }
      // if (!e.validationGroup.validate().isValid) {
      //   return;
      // }
      this.loginProcess();
      this.validation = [];
      if (!this.login) {
        this.validation.login = true;
      }
      if (!this.password) {
        this.validation.password = true;
      }
      // e.validationGroup.reset();
    },

    loginProcess() {
      const authUser = {};
      if (this.login && this.password) {
        axios.get(base_url + "/sanctum/csrf-cookie").then((response) => {
          //debug cookie
          console.log("csrf-cookie response : " + JSON.stringify(response));

          axios
            .post(base_url + "/api/login", {
              // email: this.login,
              name: this.login,
              password: this.password,
            })
            .then((res) => {
              //debug user login
              console.log(res);

              if (res.data.success) {
                authUser.data = res.data;
                authUser.token = res.data.token;
                authUser.last_login_date = new Date();
                // console.log("token " + authUser.last_login_date);
                // authUser.last_used_at = res.data.last_used_at;
                // console.log("last_used_at" + res.data.last_used_at);

                localStorage.setItem("lbUser", JSON.stringify(authUser));
                // console.log(
                //   "localStorage.setItem : " + JSON.stringify(authUser)
                // );

                //set localStorage
                localStorage.setItem("loggedIn", "true");
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("useremail", res.data.user.email);
                localStorage.setItem("username", res.data.user.name);
                localStorage.setItem("roleid", res.data.user.role_id);
                localStorage.setItem("usrid", res.data.user.id);
                localStorage.setItem("wrhid", res.data.user.warehouse_id);

                console.log("usrid : " + res.data.user.id);
                console.log("user email : " + res.data.user.email);
                console.log("role id : " + res.data.user.role_id);

                //change state
                this.loggedIn = true;
                notify("Login Berhasil..");
                // return this.$router.push({ name: "/dashboard" });
                if (res.data.user.role_id == 1) {
                  router.push("/welcome-super-admin");
                  // this.$router.go();
                } else if (res.data.user.role_id == 2) {
                  router.push("/welcome-manager");
                  // this.$router.go();
                } else if (res.data.user.role_id == 4) {
                  router.push("/welcome-admin");
                  // this.$router.go();
                } else if (res.data.user.role_id == 5) {
                  router.push("/welcome");
                  // this.$router.go();
                }
              } else {
                //set state login failed
                this.loginFailed = true;
                console.log("loginFailed" + this.loginFailed);
              }
            })
            .catch((error) => {
              console.log(error);
              this.loginstatus = 1;
            });
        });
      }
      // this.validation = [];
      // if (!this.login) {
      //   this.validation.login = true;
      // }
      // if (!this.password) {
      //   this.validation.password = true;
      // }
      // e.validationGroup.reset();
    },
    onEnterKey() {
      this.onLoginClick();
    },

    // login() {
    //   // var vthis = this;
    //   if (this.user.email && this.user.password) {
    //     axios
    //       .get("http://localhost:8000/sanctum/csrf-cookie")
    //       .then((response) => {
    //         //debug cookie
    //         console.log(response);

    //         axios
    //           .post("http://localhost:8000/api/login", {
    //             email: this.user.email,
    //             password: this.user.password,
    //           })
    //           .then((res) => {
    //             //debug user login
    //             console.log(res);

    //             if (res.data.success) {
    //               //set localStorage
    //               localStorage.setItem("loggedIn", "true");

    //               //set localStorage Token
    //               localStorage.setItem("token", res.data.token);

    //               //change state
    //               this.loggedIn = true;

    //               //redirect dashboard
    //               // return this.$router.push({ name: "/dashboard" });
    //               return this.$router.push(
    //                 this.$route.query.redirect || "/dashboard"
    //               );
    //             } else {
    //               //set state login failed
    //               this.loginFailed = true;
    //             }
    //           })
    //           .catch((error) => {
    //             console.log(error);
    //           });
    //       });
    //   }

    //   this.validation = [];

    //   if (!this.user.email) {
    //     this.validation.email = true;
    //   }

    //   if (!this.user.password) {
    //     this.validation.password = true;
    //   }
    // },
  },

  computed: {
    validationGroup: function () {
      return this.$refs[this.groupRefKey].instance;
    },
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
.app {
  background-color: #2f8649;
  background: url("~@/assets/images/ljr-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // display: flex;
  // height: 100%;
  // width: 100%;
}
.login-header {
  text-align: center;
  margin-bottom: 10px;
  .title1 {
    color: $base-accent;
    font-weight: bold;
    font-size: 30px;
    margin: 0;
  }
  .title2 {
    color: $base-accent;
    font-weight: bold;
    font-size: 20px;
    margin: 0;
  }
}

.version {
  color: gray;
  font-weight: bold;
  font-size: 12px;
  margin: 0;
}
.login-notif {
  text-align: center;
  margin-bottom: 40px;
  color: red;
  font-weight: bold;
  font-size: 14px;
}
</style>
